var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('racc-stars'),_c('v-overlay',{staticClass:"d-block",attrs:{"value":true,"opacity":"0.3"}},[_c('v-container',{staticClass:"pa-0 pt-3",staticStyle:{"max-width":"1360px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-block d-md-none mb-3 rounded",attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"11"}},[_c('v-btn',{staticClass:"rounded",attrs:{"block":"","color":"transparent","elevation":"0"},on:{"click":function($event){return _vm.$router.push({
              path: '/blog',
              name: 'Blog',
              meta: { pageName: 'blogPage' },
            })}}},[_c('v-icon',{staticClass:"mr-auto"},[_vm._v(" mdi-keyboard-backspace ")])],1)],1)],1),_c('v-row',{staticClass:"pb-6",staticStyle:{"height":"100vh"},attrs:{"justify":"center","align":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block text-right py-0",attrs:{"align-self":"stretch","cols":"1"}},[_c('v-btn',{staticClass:"rounded-lg d-block",staticStyle:{"height":"100%"},attrs:{"large":"","color":"transparent","elevation":"0"},on:{"click":function($event){return _vm.$router.push({
              path: '/blog',
              name: 'Blog',
              meta: { pageName: 'blogPage' },
            })}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-spacer',{staticClass:"d-block d-md-none"}),_c('v-col',{attrs:{"cols":"11"}},[_c('hero-alt',{staticClass:"pb-6"}),_c(_vm.comp,{tag:"component"})],1),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }