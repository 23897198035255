<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <base-business-info
        title="FIND ME ON SOCIAL MEDIA"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <base-projects-info
        title="CHECKOUT MY PROJECTS"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {

  }
</script>

<style>

</style>
